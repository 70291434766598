.transfers-content-wrapper {
  padding-top: 26px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 26px;
  gap: 20px;

  @media (max-width: 1248px) {
    flex-direction: column;
  }
}

.transfers-filter-wrapper {
  width: 100%;
  height: fit-content;
  background-color: white;

  padding: 20px 16px 27px 16px;

  @media (max-width: 1248px) {
    max-width: 100%;
  }
}

.filter-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 1rem;
}

.filter-item {
  max-width: 400px;
  min-width: 220px;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.filter-label {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: rgba(68, 79, 89, 0.6);
}

.selected path {
  fill: #64a0e3 !important;
  fill-opacity: 1;
}
