.mantine-Popover-dropdown {
  margin-top: -7px;
  filter: drop-shadow(0px 1px 2px rgba(100, 100, 100, 0.15));
  border: none !important;
  z-index: 99999 !important;
}

.mantine-Combobox-option[data-combobox-active] {
  // background-color: #E0ECF9;
  color: $brand-colour;
}

.mantine-Popover-dropdown {
  padding: 0px !important;
}

.mantine-Combobox-option {
  border-radius: 0px !important;

  &:hover {
    // background-color: #EFF5FC !important;
    color: $brand-colour;
  }
}

.mantine-Combobox-search {
  border: 1px solid $border-primary !important;
  border-radius: 4px !important;
  padding: 9px 15px !important;
  margin-bottom: 0px !important;
}

.mantine-Combobox-wrapper {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
}

.mantine-Input-input:focus {
  border-color: $primary-border-subtle !important;
}

.comboBox-close-icon {
  &:hover {
    background-color: $border-primary;
    cursor: pointer;
    border-radius: 4px;
  }
}

.mantine-InputBase-section[data-condition='true'] {
  margin-right: 12px !important;
}

.simpleOption {
  div {
    button {
      background-color: #f9f9f9 !important;
      border-color: #f9f9f9 !important;
      padding: 9px 15px;
    }
  }
}
