$variable-prefix: vz-; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix: $variable-prefix;

// Color system

// scss-docs-start gray-color-variables
$brand-colour: #64a0e3;
$bg-sidebar-brand: #444f59;
$white: #fff;
$black: #000;
$overlay: #516196;

// scss-docs-start theme-color-variables
//feito
$primary: #444f59;
$secondary: #8f959b;
$success: #50cfbd;
$info: $brand-colour;
$warning: #f7b84b;
$danger: #f06548;
$disabled: rgba(68, 79, 89, 0.25);
$text-soft-info: #5d94d1;
$portal: #f5f5f6;
$container: $white;
$placeholder: #dadcde;

$status-new: #59cbeb;
$status-manufacturing: #516e9b;
$status-ready-to-process: #9e69ac;
$status-ready-to-ship: #f7a745;
$status-ready-to-pick-up: #f7c922;
$status-shipped: #46d9c1;
$status-delivered: #6edb6e;
$status-open-order: #7eb8f8;
$status-closed: #b7c1c9;
$status-canceled: #ff5656;
$status-package-received: #6edb6e;
$status-approved: #46d9c1;
$status-denied: $danger;
$status-refund-completed: #6edb6e;
$status-pending: #f7c922;
$status-canceled: $danger;
$status-processing: #516e9b;
$status-out-of-stock: $danger;
$status-confirmed: #46d9c1;
$status-waiting-for-packages: #f7c922;

$battery-20: #f06548;
$battery-40: #f7a745;
$battery-60: #f7c922;
$battery-80: #83de83;
$battery-100: #4fb6a5;

//borders
$border-primary: #dedede;
$border-secondary: #e9ebec;

//feito
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'white': $white,
  'status-new': $status-new,
  'status-manufacturing': $status-manufacturing,
  'status-ready-to-process': $status-ready-to-process,
  'status-ready-to-ship': $status-ready-to-ship,
  'status-ready-to-pick-up': $status-ready-to-pick-up,
  'status-shipped': $status-shipped,
  'status-processing': $status-processing,
  'status-delivered': $status-delivered,
  'status-open-order': $status-open-order,
  'status-closed': $status-closed,
  'status-package-received': $status-package-received,
  'status-approved': $status-approved,
  'status-denied': $status-denied,
  'status-refund-completed': $status-refund-completed,
  'status-pending': $status-pending,
  'status-canceled': $status-canceled,
  'status-out-of-stock': $status-out-of-stock,
  'status-confirmed': $status-confirmed,
  'status-waiting-for-packages': $status-waiting-for-packages,
  'soft-info-text': $text-soft-info,
  'battery-20': $battery-20,
  'battery-40': $battery-40,
  'battery-60': $battery-60,
  'battery-80': $battery-80,
  'battery-100': $battery-100,
  'portal': $portal,
  'sidebar-brand': $bg-sidebar-brand,
  'container': $container,
  'border-primary': $border-primary,
  'border-secondary': $border-secondary,
  'disabled': $disabled,
  'placeholder': $placeholder
);

$text-primary: #444f59;
$text-secondary: #8f959b;
$text-info: $brand-colour;
$text-success: #50cfbd;
$text-warning: #f7b84b;
$text-danger: #f06548;
$text-disabled: rgba(68, 79, 89, 0.25);
$text-accordion-label: red;

$text-primary: #444f59;
$text-secondary: #8f959b;
$text-info: $brand-colour;
$text-success: #50cfbd;
$text-warning: #f7b84b;
$text-danger: #f06548;
$text-disabled: rgba(68, 79, 89, 0.25);

// colocar cores texto
$primary-text-emphasis: shade-color($primary, 15%);
$secondary-text-emphasis: shade-color($secondary, 15%);
$success-text-emphasis: shade-color($success, 15%);
$info-text-emphasis: shade-color($info, 15%);
$warning-text-emphasis: shade-color($warning, 15%);
$danger-text-emphasis: shade-color($danger, 15%);

// colocar cores background
$primary-bg-subtle: tint-color($primary, 85%);
$secondary-bg-subtle: tint-color($secondary, 85%);
$success-bg-subtle: tint-color($success, 85%);
$info-bg-subtle: tint-color($info, 85%);
$warning-bg-subtle: tint-color($warning, 85%);
$danger-bg-subtle: tint-color($danger, 85%);

// colocar cores borders
$primary-border-subtle: tint-color($primary, 60%);
$secondary-border-subtle: tint-color($secondary, 60%);
$success-border-subtle: tint-color($success, 60%);
$info-border-subtle: tint-color($info, 60%);
$warning-border-subtle: tint-color($warning, 60%);
$danger-border-subtle: tint-color($danger, 60%);
