.base-link {
  width: auto !important;
  border: 1px solid transparent;
  position: relative;
  text-decoration: none;
  padding: 3px;
  display: inline-block;
  color: $brand-colour;

  &.disabled {
    color: rgba(68, 79, 89, 0.25) !important;

    div:nth-child(1) {
      color: rgba(68, 79, 89, 0.25) !important;

      & div div {
        background-color: rgba(68, 79, 89, 0.25) !important;
      }

      & div svg path {
        fill: rgba(68, 79, 89, 0.25) !important;
      }
    }
    &:focus-visible {
      border: 1px solid rgba(68, 79, 89, 0.25) !important;
      border-radius: 4px;
    }

    &:active {
      div:nth-child(1) {
        color: rgba(68, 79, 89, 0.25) !important;

        & div div {
          background-color: rgba(68, 79, 89, 0.25) !important;
        }

        & div svg path {
          fill: rgba(68, 79, 89, 0.25) !important;
        }
      }
    }

    &:hover {
      div:nth-child(1) {
        color: rgba(68, 79, 89, 0.25) !important;

        & div div {
          background-color: rgba(68, 79, 89, 0.25) !important;
        }

        & div svg path {
          fill: rgba(68, 79, 89, 0.25) !important;
        }
      }
    }
  }

  & div svg path {
    fill: $brand-colour !important;
  }

  &:focus-visible {
    border: 1px solid $brand-colour;
    border-radius: 4px;
  }

  &:active {
    div:nth-child(1) {
      color: #4776ab !important;
      & div div {
        background-color: #4776ab !important;
      }

      & div svg path {
        fill: #4776ab !important;
      }
    }
  }

  &:hover {
    div:nth-child(1) {
      color: #5d94d1;
      & div div {
        background-color: #5d94d1;
      }

      & div svg path {
        fill: #5d94d1;
      }
    }
  }
}
