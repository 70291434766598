.display_1 {
  font-size: 40px !important;
  line-height: 48px !important;
  font-weight: 700 !important;
  color: #444f59;
}

.display_2 {
  font-size: 30px !important;
  line-height: 36px !important;
  font-weight: 500 !important;
  color: #444f59;
}

.display_3 {
  font-size: 18px !important;
  line-height: 21.6px !important;
  font-weight: 700 !important;
  color: #444f59;
}

.page_title {
  font-size: 15px !important;
  line-height: 18px !important;
  font-weight: 700 !important;
  color: #444f59;
}

.heading_1 {
  font-size: 16px !important;
  line-height: 19px !important;
  font-weight: 500 !important;
  color: #212529;
}

.heading_2 {
  font-size: 14px !important;
  line-height: 17px !important;
  font-weight: 700 !important;
  color: #444f59;
}

.heading_3 {
  font-size: 14px !important;
  line-height: 16.8px !important;
  font-weight: 500 !important;
  color: #444f59;
}

.heading_4 {
  font-size: 13px !important;
  line-height: 16px !important;
  font-weight: 700 !important;
  color: #444f59;
}

.heading_5 {
  font-size: 13px !important;
  line-height: 16px !important;
  font-weight: 500 !important;
  color: #444f59;
}

.heading_6 {
  font-size: 13px !important;
  line-height: 16px !important;
  font-weight: 400 !important;
  color: #444f59;
}

.heading_7 {
  font-size: 12px !important;
  line-height: 16px !important;
  font-weight: 500 !important;
  color: #444f59;
}

.bodyText_1 {
  font-size: 17px !important;
  line-height: 20px !important;
  font-weight: 500 !important;
  color: #444f59;
}

.bodyText_2 {
  font-size: 16px !important;
  line-height: 19px !important;
  font-weight: 500 !important;
  color: #444f59;
}

.bodyText_3 {
  font-size: 15px !important;
  line-height: 18px !important;
  font-weight: 400 !important;
  color: #444f59;
}

.bodyText_4 {
  font-size: 14px !important;
  line-height: 17px !important;
  font-weight: 700 !important;
  color: #444f59;
}

.bodyText_5 {
  font-size: 14px !important;
  line-height: 17px !important;
  font-weight: 500 !important;
  color: #444f59;
}

.bodyText_6 {
  font-size: 14px !important;
  line-height: 16px !important;
  font-weight: 400 !important;
  color: #444f59;
}

.bodyText_7 {
  font-size: 13px !important;
  line-height: 16px !important;
  font-weight: 700 !important;
  color: #444f59;
}

.bodyText_8 {
  font-size: 13px !important;
  line-height: 16px !important;
  font-weight: 500 !important;
  color: #444f59;
}

.bodyText_9 {
  font-size: 13px !important;
  line-height: 16px !important;
  font-weight: 400 !important;
  color: #444f59;
}

.bodyText_10 {
  font-size: 12px !important;
  line-height: 14.4px !important;
  font-weight: 400 !important;
  color: #444f59;
}

.bodyText_11 {
  font-size: 11px !important;
  line-height: 13.2px !important;
  font-weight: 400 !important;
  color: #444f59;
}

.bodyText_12 {
  font-size: 9.5px !important;
  font-weight: 400 !important;
  color: #444f59;
  line-height: 11px;
}
