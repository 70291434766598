.row-bread {
  width: 100%;
}

.page-title-box-2 {
  padding: 15px 1.5rem 8px 1.5rem !important;
  background-color: var(--vz-secondary-bg);
  box-shadow: var(--vz-page-title-box-shadow);
  border-bottom: 1px solid var(--vz-page-title-border);
  /* top right bottom left */
  margin: -17px 0px 0px 0px;
}

.page-title-right {
  margin: 0px 10px 0px 0px;
}

.page-title-main {
  color: #495057;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.parent-option {
  color: #64a0e3;
}

.breadcrumb-item {
  > a {
    color: var(--#{$prefix}body-color);
  }

  &.active {
    color: #878a99 !important;
  }
}
