@import '../../assets/scss/colours.scss';

.card-form {
  height: 100%;
  width: 100%;
}

.cardbody-articles-details {
  padding: 9px 18px !important;
}

.icon {
  width: 40px;
  height: 40px;
  border: 1px solid;
  border-radius: 50%;
  background-color: gray;
}

.image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;
}

.product-image {
  width: 84px;
}

.content-wrapper {
  margin-left: 10px;
}

.lower-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 13px;
}

.box {
  width: 100%;
  margin-left: 15px;
}

.upper-row {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-bottom: 1px solid #f2f2f2;
}

.labels-tags {
  color: rgba(68, 79, 89, 0.6) !important;
}

.buttons-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
}

.ref-text {
  color: $brand-colour;
}

.stock-link {
  color: $brand-colour;
}

.article-details-tab-container {
  margin-inline: 24px;
}

@media (max-width: 1450px) {
  .card-articles-details {
    margin: 20px 0 20px 0 !important;
  }

  .article-details-tab-container {
    margin-inline: 0;
  }

  .box {
    margin-left: 20px;
  }

  .image-wrapper {
    width: 70%;
    height: 350px;
  }

  .product-image {
    width: auto;
    height: 350px;
  }

  .lower-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .lower-row > div {
    margin-bottom: 25px;
  }

  .box-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 !important;
  }

  .buttons-wrap {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .box {
    margin: 10px;
  }

  .image-wrapper {
    display: none;
  }

  .lower-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .lower-row > div {
    margin-bottom: 25px;
  }

  .box-items {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .buttons-wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

/* PRODUCT DETAIL */

.title {
  border-bottom: 2px solid #e9ebec;
}

.input-wrap {
  max-width: 250px;
  margin: 0 25px 0px 0px;
}

.select-wrap {
  width: 650px !important;
  margin: 0 20px 0 0px !important;
}

.language {
  display: flex;
  width: 100% !important;
  height: 100%;
  align-items: center;
}

.siu {
  margin-top: 0 !important;
  display: flex;
  align-items: flex-end;
  width: fit-content;
}

.delete-button {
  margin-top: 0 !important;
}

@media (max-width: 767px) {
  .input-wrap {
    max-width: 100%;
    margin: 5px 0px;
  }
  .packages-box {
    width: 50%;
  }
  .card-form {
    width: 100%;
    margin: 0;
  }
}

.breadcrumb-bar {
  z-index: 1001;
  right: 0px;
  left: 0;
  position: sticky;
  top: calc(70px + 1.5rem);
}

.table-row-width {
  width: 160px;
  @media (max-width: 767px) {
    width: unset;
  }
}
