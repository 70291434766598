.mantine-Combobox-groupLabel {
  color: rgb(135, 138, 153) !important;
  font-size: 12px !important;
  line-height: 18px !important;
  font-weight: 600 !important;

  &::after {
    display: none !important;
  }
}
