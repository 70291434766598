.base-btn-default {
  box-shadow: $element-shadow;
  height: 36px;
  min-width: 94px;
  padding-left: 28px;
  padding-right: 28px;
  padding-top: 14px;
  padding-bottom: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: $brand-colour;
  border-color: $brand-colour;

  &:active {
    color: white !important;
    border-color: #4776ab;
    background-color: #4776ab !important;
  }

  &:focus-visible {
    border-color: $brand-colour;
    background-color: $brand-colour;
    box-shadow: 0 0 0 0.25rem rgba(138, 183, 233, 0.4) !important;
  }

  &:hover {
    background-color: #5d94d1;
    border-color: #5d94d1;
  }

  &:disabled {
    border-color: #f2f2f2 !important;
    background-color: #f2f2f2 !important;
    color: rgba(68, 79, 89, 0.25) !important;

    &.base-btn-icon {
      & div svg path {
        fill: rgba(68, 79, 89, 0.25) !important;
      }
    }
  }

  &.btn-h-small {
    height: 30px;
  }

  &.btn-w-small {
    min-width: 94px;
  }

  &.btn-w-medium {
    min-width: 110px;
  }

  &.btn-w-large {
    min-width: 140px;
  }

  &.base-btn-icon {
    & div svg path {
      fill: white !important;
    }
  }

  &.grey {
    background-color: #f2f2f2;
    border: #f2f2f2;
    color: rgba(68, 79, 89, 0.6) !important;

    &:active {
      border-color: #cfcdcd !important;
      background-color: #cfcdcd !important;
    }

    &:focus-visible {
      border-color: #f2f2f2;
      background-color: #f2f2f2;
      box-shadow: 0 0 0 0.25rem rgba(228, 228, 228, 1) !important;
    }

    &:hover {
      background-color: #e4e4e4;
      border-color: #e4e4e4;
    }
  }

  &.red {
    background-color: #f06548;
    border-color: #f06548;
    color: white;

    &:active {
      border-color: #d85b40 !important;
      background-color: #d85b40 !important;
    }

    &:focus-visible {
      border-color: #f06548;
      background-color: #f06548;
      box-shadow: 0 0 0 0.25rem rgba(240, 101, 72, 0.4) !important;
    }

    &:hover {
      background-color: #e65f43;
      border-color: #e65f43;
    }
  }
}

.base-btn-secondary {
  box-shadow: $element-shadow;
  height: 36px;
  min-width: 94px;
  padding-left: 28px;
  padding-right: 28px;
  padding-top: 14px;
  padding-bottom: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: transparent !important;
  border-color: $brand-colour;
  color: $brand-colour;
  transition:
    fill 0.1s linear,
    stroke 0.1s linear;

  &.base-btn-icon {
    & div svg path {
      transition:
        fill 0.1s linear,
        stroke 0.1s linear;
      fill: $brand-colour;
    }
  }

  &:active {
    color: #4776ab !important;
    border-color: #4776ab !important;

    &.base-btn-icon {
      & div svg path {
        transition:
          fill 0.1s linear,
          stroke 0.1s linear;
        fill: #4776ab !important;
      }
    }
  }

  &:focus-visible {
    border-color: $brand-colour;
    background-color: $brand-colour;
    box-shadow: 0 0 0 0.25rem rgba(138, 183, 233, 0.4) !important;
    color: $brand-colour;

    &.base-btn-icon {
      & div svg path {
        transition:
          fill 0.1s linear,
          stroke 0.1s linear;
        fill: $brand-colour !important;
      }
    }
  }

  &:hover {
    background-color: #5d94d1;
    border-color: #5d94d1;
    color: #5d94d1;

    &.base-btn-icon {
      & div svg path {
        transition:
          fill 0.1s linear,
          stroke 0.1s linear;
        fill: #5d94d1;
      }
    }
  }

  &:disabled {
    border-color: rgba(68, 79, 89, 0.25) !important;
    background-color: transparent !important;
    color: rgba(68, 79, 89, 0.25) !important;

    &.base-btn-icon {
      & div svg path {
        transition:
          fill 0.1s linear,
          stroke 0.1s linear;
        fill: rgba(68, 79, 89, 0.25) !important;
      }
    }
  }

  &.btn-h-small {
    height: 30px;
  }

  &.btn-w-small {
    min-width: 94px;
  }

  &.btn-w-medium {
    min-width: 110px;
  }

  &.btn-w-large {
    min-width: 140px;
  }

  &.grey {
    background-color: transparent;
    border-color: rgba(68, 79, 89, 0.3);
    color: rgba(68, 79, 89, 0.6);

    &.base-btn-icon {
      & div svg path {
        transition:
          fill 0.1s linear,
          stroke 0.1s linear;
        fill: rgba(68, 79, 89, 0.6);
      }
    }

    &:active {
      border-color: #444f59 !important;
      background-color: transparent !important;
      color: #444f59 !important;

      &.base-btn-icon {
        & div svg path {
          transition:
            fill 0.1s linear,
            stroke 0.1s linear;
          fill: #444f59 !important;
        }
      }
    }

    &:focus-visible {
      border-color: #cfcdcd;
      background-color: transparent;
      box-shadow: 0 0 0 0.25rem rgba(228, 228, 228, 1) !important;
    }

    &:hover {
      background-color: transparent;
      border-color: rgba(68, 79, 89, 0.6);
      color: rgba(68, 79, 89, 0.6);

      &.base-btn-icon {
        & div svg path {
          transition:
            fill 0.1s linear,
            stroke 0.1s linear;
          fill: rgba(68, 79, 89, 0.6);
        }
      }
    }

    &.base-btn-icon {
      & div svg path {
        fill: rgba(68, 79, 89, 0.6) !important;
      }
    }
  }

  &.red {
    background-color: transparent;
    border-color: #f06548;
    color: #f06548 !important;

    &:active {
      border-color: #d85b40 !important;
      background-color: transparent !important;
    }

    &:focus-visible {
      border-color: #f06548;
      background-color: #f06548;
      box-shadow: 0 0 0 0.25rem rgba(240, 101, 72, 0.4) !important;
    }

    &:hover {
      background-color: transparent;
      border-color: #e65f43;
    }

    &.base-btn-icon {
      & div svg path {
        fill: #f06548 !important;
      }
    }
  }
}

.base-btn-secondary-black {
  box-shadow: $element-shadow;
  height: 36px;
  min-width: 94px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: transparent !important;
  border-color: $brand-colour;
  color: #444f59;
  transition:
    fill 0s linear,
    stroke 0s linear;

  &.base-btn-icon {
    & div svg path {
      transition:
        fill 0s linear,
        stroke 0s linear;
      fill: #444f59 !important;
    }
  }

  &:active {
    color: white !important;
    border-color: #5d94d1 !important;
    background-color: #5d94d1 !important;

    &.base-btn-icon {
      & div svg path {
        transition:
          fill 0s linear,
          stroke 0s linear;
        fill: white !important;
      }
    }
  }

  &:focus-visible {
    border-color: $brand-colour;
    background-color: $brand-colour;
    box-shadow: 0 0 0 0.25rem rgba(138, 183, 233, 0.4) !important;
    color: $brand-colour;

    &.base-btn-icon {
      & div svg path {
        transition:
          fill 0s linear,
          stroke 0s linear;
        fill: $brand-colour !important;
      }
    }
  }

  &:hover {
    background-color: #5d94d1 !important;
    border-color: #5d94d1;
    color: white;

    &.base-btn-icon {
      & div svg path {
        transition:
          fill 0s linear,
          stroke 0s linear;
        fill: white !important;
      }
    }
  }

  &:disabled {
    border-color: rgba(68, 79, 89, 0.25) !important;
    background-color: transparent !important;
    color: rgba(68, 79, 89, 0.25) !important;

    &.base-btn-icon {
      & div svg path {
        transition:
          fill 0.1s linear,
          stroke 0.1s linear;
        fill: rgba(68, 79, 89, 0.25) !important;
      }
    }
  }

  &.btn-h-small {
    height: 30px;
  }

  &.btn-w-small {
    min-width: 94px;
  }

  &.btn-w-medium {
    min-width: 110px;
  }

  &.btn-w-large {
    min-width: 140px;
  }

  &.grey {
    background-color: transparent;
    border-color: rgba(68, 79, 89, 0.3);
    color: rgba(68, 79, 89, 0.6);

    &.base-btn-icon {
      & div svg path {
        transition:
          fill 0.1s linear,
          stroke 0.1s linear;
        fill: rgba(68, 79, 89, 0.6);
      }
    }

    &:active {
      border-color: #444f59 !important;
      background-color: transparent !important;
      color: #444f59 !important;

      &.base-btn-icon {
        & div svg path {
          transition:
            fill 0.1s linear,
            stroke 0.1s linear;
          fill: #444f59 !important;
        }
      }
    }

    &:focus-visible {
      border-color: #cfcdcd;
      background-color: transparent;
      box-shadow: 0 0 0 0.25rem rgba(228, 228, 228, 1) !important;
    }

    &:hover {
      background-color: transparent;
      border-color: rgba(68, 79, 89, 0.6);
      color: rgba(68, 79, 89, 0.6);

      &.base-btn-icon {
        & div svg path {
          transition:
            fill 0.1s linear,
            stroke 0.1s linear;
          fill: rgba(68, 79, 89, 0.6);
        }
      }
    }

    &.base-btn-icon {
      & div svg path {
        fill: rgba(68, 79, 89, 0.6) !important;
      }
    }
  }

  &.red {
    background-color: transparent;
    border-color: #f06548;
    color: #f06548 !important;

    &:active {
      border-color: #d85b40 !important;
      background-color: transparent !important;
    }

    &:focus-visible {
      border-color: #f06548;
      background-color: #f06548;
      box-shadow: 0 0 0 0.25rem rgba(240, 101, 72, 0.4) !important;
    }

    &:hover {
      background-color: transparent;
      border-color: #e65f43;
    }

    &.base-btn-icon {
      & div svg path {
        fill: #f06548 !important;
      }
    }
  }
}

.icon-btn-default {
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: $brand-colour;
  border-color: $brand-colour;
  padding: 8px;

  &.round {
    border-radius: 50% !important;
  }

  &.icon-btn-icon {
    & div svg path {
      transition:
        fill 0.1s linear,
        stroke 0.1s linear;
      fill: white;
    }
  }

  &:active {
    border-color: #5d94d1 !important;
    background-color: #5d94d1 !important;
  }

  &:focus-visible {
    border-color: #5d94d1;
    background-color: #5d94d1;
    box-shadow: 0 0 0 0.25rem rgba(138, 183, 233, 0.4) !important;
  }

  &:hover {
    background-color: #5d94d1;
    border-color: #5d94d1;
  }

  &.green {
    background-color: #50cfbd;
    border-color: #50cfbd;

    &:active {
      border-color: #29baa6 !important;
      background-color: #29baa6 !important;
    }

    &:focus-visible {
      border-color: #29baa6;
      background-color: #29baa6;
      box-shadow: 0 0 0 0.25rem rgba(80, 207, 189, 0.4) !important;
    }

    &:hover {
      background-color: #29baa6;
      border-color: #29baa6;
    }
  }

  &.light-grey {
    background-color: #f3f6f9;
    border-color: #f3f6f9;

    &:active {
      border-color: #f3f6f9 !important;
      background-color: #f3f6f9 !important;
    }

    &:focus-visible {
      border-color: #f3f6f9;
      background-color: #f3f6f9;
      box-shadow: 0 0 0 0.25rem rgba(80, 207, 189, 0.4) !important;
    }

    &:hover {
      background-color: #f3f6f9;
      border-color: #f3f6f9;
    }
  }

  &.yellow {
    background-color: #f7b84b;
    border-color: #f7b84b;

    &:active {
      border-color: #eeb046 !important;
      background-color: #eeb046 !important;
    }

    &:focus-visible {
      border-color: #eeb046;
      background-color: #eeb046;
      box-shadow: 0 0 0 0.25rem rgba(238, 176, 70, 0.4) !important;
    }

    &:hover {
      background-color: #eeb046;
      border-color: #eeb046;
    }
  }

  &.red {
    background-color: #f06548;
    border-color: #f06548;

    &:active {
      border-color: #e65f43 !important;
      background-color: #e65f43 !important;
    }

    &:focus-visible {
      border-color: #f06548;
      background-color: #f06548;
      box-shadow: 0 0 0 0.25rem rgba(240, 101, 72, 0.4) !important;
    }

    &:hover {
      background-color: #e65f43;
      border-color: #e65f43;
    }
  }
}

.icon-btn-small {
  height: 30px !important;
  width: 30px !important;
}

.icon-btn-secondary {
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: transparent;
  border-color: $brand-colour;
  padding: 8px;

  &.round {
    border-radius: 50% !important;
  }

  &.icon-btn-icon {
    & div svg path {
      transition:
        fill 0.1s linear,
        stroke 0.1s linear;
      fill: $brand-colour;
    }
  }

  &:active {
    border-color: #5d94d1 !important;
    background-color: #5d94d1 !important;

    & div svg path {
      transition:
        fill 0.1s linear,
        stroke 0.1s linear;
      fill: white;
    }
  }

  &:focus-visible {
    border-color: #5d94d1;
    background-color: #5d94d1;
    box-shadow: 0 0 0 0.25rem rgba(138, 183, 233, 0.4) !important;

    & div svg path {
      transition:
        fill 0.1s linear,
        stroke 0.1s linear;
      fill: white;
    }
  }

  &:hover {
    background-color: #5d94d1;
    border-color: #5d94d1;

    & div svg path {
      transition:
        fill 0.1s linear,
        stroke 0.1s linear;
      fill: white;
    }
  }

  &:disabled {
    background-color: transparent;
    border-color: rgba(68, 79, 89, 0.25) !important;

    &.icon-btn-icon {
      & div svg path {
        transition:
          fill 0.1s linear,
          stroke 0.1s linear;
        fill: rgba(68, 79, 89, 0.25) !important;
      }
    }
  }

  &.green {
    background-color: transparent;
    border-color: #50cfbd;

    &.icon-btn-icon {
      & div svg path {
        transition:
          fill 0.1s linear,
          stroke 0.1s linear;
        fill: #50cfbd;
      }
    }

    &:active {
      border-color: #29baa6 !important;
      background-color: #29baa6 !important;

      &.icon-btn-icon {
        & div svg path {
          transition:
            fill 0.1s linear,
            stroke 0.1s linear;
          fill: white;
        }
      }
    }

    &:focus-visible {
      border-color: #29baa6;
      background-color: #29baa6;
      box-shadow: 0 0 0 0.25rem rgba(80, 207, 189, 0.4) !important;

      &.icon-btn-icon {
        & div svg path {
          transition:
            fill 0.1s linear,
            stroke 0.1s linear;
          fill: white;
        }
      }
    }

    &:hover {
      background-color: #29baa6;
      border-color: #29baa6;

      &.icon-btn-icon {
        & div svg path {
          transition:
            fill 0.1s linear,
            stroke 0.1s linear;
          fill: white;
        }
      }
    }
  }

  &.yellow {
    background-color: transparent;
    border-color: #f7b84b;

    &.icon-btn-icon {
      & div svg path {
        transition:
          fill 0.1s linear,
          stroke 0.1s linear;
        fill: #f7b84b;
      }
    }

    &:active {
      border-color: #eeb046 !important;
      background-color: #eeb046 !important;

      &.icon-btn-icon {
        & div svg path {
          transition:
            fill 0.1s linear,
            stroke 0.1s linear;
          fill: white;
        }
      }
    }

    &:focus-visible {
      border-color: #eeb046;
      background-color: #eeb046;
      box-shadow: 0 0 0 0.25rem rgba(238, 176, 70, 0.4) !important;

      &.icon-btn-icon {
        & div svg path {
          transition:
            fill 0.1s linear,
            stroke 0.1s linear;
          fill: white;
        }
      }
    }

    &:hover {
      background-color: #eeb046;
      border-color: #eeb046;

      &.icon-btn-icon {
        & div svg path {
          transition:
            fill 0.1s linear,
            stroke 0.1s linear;
          fill: white;
        }
      }
    }
  }

  &.red {
    background-color: transparent;
    border-color: #f06548;

    &.icon-btn-icon {
      & div svg path {
        transition:
          fill 0.1s linear,
          stroke 0.1s linear;
        fill: #f06548;
      }
    }

    &:active {
      border-color: #e65f43 !important;
      background-color: #e65f43 !important;

      &.icon-btn-icon {
        & div svg path {
          transition:
            fill 0.1s linear,
            stroke 0.1s linear;
          fill: white;
        }
      }
    }

    &:focus-visible {
      border-color: #f06548;
      background-color: #f06548;
      box-shadow: 0 0 0 0.25rem rgba(240, 101, 72, 0.4) !important;

      &.icon-btn-icon {
        & div svg path {
          transition:
            fill 0.1s linear,
            stroke 0.1s linear;
          fill: white;
        }
      }
    }

    &:hover {
      background-color: #e65f43;
      border-color: #e65f43;

      &.icon-btn-icon {
        & div svg path {
          transition:
            fill 0.1s linear,
            stroke 0.1s linear;
          fill: white;
        }
      }
    }
  }

  &.grey {
    background-color: transparent;
    border-color: rgba(68, 79, 89, 0.3);

    &.icon-btn-icon {
      & div svg path {
        transition:
          fill 0.1s linear,
          stroke 0.1s linear;
        fill: rgba(68, 79, 89, 0.3);
      }
    }

    &:active {
      background-color: rgba(68, 79, 89, 0.3) !important;
      border-color: transparent !important;

      &.icon-btn-icon {
        & div svg path {
          transition:
            fill 0.1s linear,
            stroke 0.1s linear;
          fill: white;
        }
      }
    }

    &:focus-visible {
      background-color: rgba(68, 79, 89, 0.3);
      box-shadow: 0 0 0 0.25rem rgba(68, 79, 89, 0.4) !important;
      border-color: transparent !important;

      &.icon-btn-icon {
        & div svg path {
          transition:
            fill 0.1s linear,
            stroke 0.1s linear;
          fill: white;
        }
      }
    }

    &:hover {
      background-color: rgba(68, 79, 89, 0.3) !important;
      border-color: transparent !important;

      &.icon-btn-icon {
        & div svg path {
          transition:
            fill 0.1s linear,
            stroke 0.1s linear;
          fill: white;
        }
      }
    }
  }
}

.icon-btn-noBorder {
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: transparent !important;
  padding: 8px;
  border: none;

  &.icon-btn-icon {
    & div svg path {
      transition:
        fill 0.1s linear,
        stroke 0.1s linear;
      fill: rgba(68, 79, 89, 0.3);
    }
  }

  &:active {
    &.icon-btn-icon {
      & div svg path {
        transition:
          fill 0.1s linear,
          stroke 0.1s linear;
        fill: #444f59 !important;
      }
    }
  }

  &:focus-visible {
    box-shadow: 0 0 0 0.25rem rgba(68, 79, 89, 0.3) !important;

    &.icon-btn-icon {
      & div svg path {
        transition:
          fill 0.1s linear,
          stroke 0.1s linear;
        fill: #444f59;
      }
    }
  }

  &:hover {
    &.icon-btn-icon {
      & div svg path {
        transition:
          fill 0.1s linear,
          stroke 0.1s linear;
        fill: #444f59;
      }
    }
  }

  &.black {
    &.icon-btn-icon {
      & div svg path {
        transition:
          fill 0.1s linear,
          stroke 0.1s linear;
        fill: rgba(249, 249, 249, 0.3) !important;
      }
    }

    &:active {
      &.icon-btn-icon {
        & div svg path {
          transition:
            fill 0.1s linear,
            stroke 0.1s linear;
          fill: #f9f9f9 !important;
        }
      }
    }

    &:focus-visible {
      box-shadow: 0 0 0 0.25rem rgba(68, 79, 89, 0.3) !important;

      &.icon-btn-icon {
        & div svg path {
          transition:
            fill 0.1s linear,
            stroke 0.1s linear;
          fill: #f9f9f9;
        }
      }
    }

    &:hover {
      &.icon-btn-icon {
        & div svg path {
          transition:
            fill 0.1s linear,
            stroke 0.1s linear;
          fill: #f9f9f9 !important;
        }
      }
    }
  }
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement='top'] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--vz-tooltip-arrow-height)
    calc(var(--vz-tooltip-arrow-width) * 0.5) 0;
  border-top-color: #444f59 !important;
  margin-top: -10px !important;
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement='bottom'] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--vz-tooltip-arrow-width) * 0.5)
    var(--vz-tooltip-arrow-height);
  border-bottom-color: #444f59;
  margin-bottom: -10px !important;
}

.base-button-counter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  padding: 0px 6px;
  border-radius: 4px;
  font-size: 9.75px;
  font-weight: 600;
  margin-left: 8px;
  color: white !important;
}

.base-btn-alert {
  height: 10px;
  width: 10px;
  border-radius: 10px;
  border: 2px solid white;
  background-color: #f06548;
  position: absolute;
  top: -5px;
  right: -5px;
}

.base-btn-alert-counter {
  min-width: 23px;
  min-height: 16px;
  padding: 2px 4px;
  background-color: #50cfbd;
  border-radius: 23px;
  position: absolute;
  top: -12px;
  right: -15px;
}

.bell-icon {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: $brand-colour;
  border-color: $brand-colour;
  padding: 8px;
  border-radius: 50% !important;
  border: none;

  background-color: #f3f6f9;
  border-color: #f3f6f9;

  &:active {
    border-color: #e4e4e4 !important;
    background-color: #e4e4e4 !important;
  }

  &:focus-visible {
    border-color: #e4e4e4;
    background-color: #e4e4e4;
    box-shadow: 0 0 0 0.25rem rgba(80, 207, 189, 0.4) !important;
  }

  &:hover {
    background-color: #e4e4e4;
    border-color: #e4e4e4;
  }
}

.burguer-icon {
  position: relative;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: $brand-colour;
  border-color: $brand-colour;
  padding: 8px;
  border-radius: 50% !important;
  border: none;

  background-color: #f3f6f9;
  border-color: #f3f6f9;

  &:active {
    border-color: #e4e4e4 !important;
    background-color: #e4e4e4 !important;
  }

  &:focus-visible {
    border-color: #e4e4e4;
    background-color: #e4e4e4;
    box-shadow: 0 0 0 0.25rem rgba(80, 207, 189, 0.4) !important;
  }

  &:hover {
    background-color: #e4e4e4;
    border-color: #e4e4e4;
  }
}

.green-noti {
  position: absolute;
  height: 8px;
  width: 8px;
  background-color: #50cfbd;
  top: -5px;
  right: -5px;
  border-radius: 50%;
}
