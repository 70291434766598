.filters-height {
  height: calc(100vh - 148px);
  width: 500px;

  @media (min-width: 576px) {
    height: calc(100vh - 129px);
  }
}

.products-content-wrapper {
  display: flex;
  flex-direction: row;
  padding-top: 26px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 26px;
  gap: 20px;

  @media (max-width: 1248px) {
    flex-direction: column;
  }
}

.products-show-button {
  height: 30px;
  width: 30px;
  padding: 7px;
  border: 1px solid #64a0e3;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s linear;

  &:hover {
    background-color: #64a0e3;

    & div {
      & svg path {
        fill: white;
      }
    }
  }
}

.products-show-button-blue {
  height: 30px;
  width: 30px;
  padding: 7px;
  border: 1px solid #64a0e3;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s linear;
  background-color: #64a0e3;

  &:hover {
    background-color: white;

    & div {
      & svg path {
        fill: #64a0e3;
      }
    }
  }
}
