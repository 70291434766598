.base-check-default {
  border-color: $brand-colour !important;
  height: 14px !important;
  width: 14px !important;
  margin-top: 0px !important;

  &.disabled {
    border-color: $brand-colour !important;
  }

  &:hover {
    cursor: pointer;
  }

  &.black {
    border-color: #444f59 !important;

    &.base-check-checked {
      background-color: #444f59 !important;
    }

    &.disabled {
      border-color: #444f59 !important;
    }
  }

  &.green {
    border-color: #50cfbd !important;

    &.base-check-checked {
      background-color: #50cfbd !important;
    }

    &.disabled {
      border-color: #50cfbd !important;
    }
  }

  &.yellow {
    border-color: #f7b84b !important;

    &.base-check-checked {
      background-color: #f7b84b !important;
    }

    &.disabled {
      border-color: #f7b84b !important;
    }
  }

  &.red {
    border-color: #f06548 !important;

    &.base-check-checked {
      background-color: #f06548 !important;
    }

    &.disabled {
      border-color: #f06548 !important;
    }
  }
}

.form-check .form-check-input {
  float: left;
  margin-left: 0;
}

.form-check {
  display: flex;
  min-height: unset;
  padding-left: 0px;
  margin-bottom: 0px;
}
