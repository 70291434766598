.products-filter-wrapper {
  width: 100%;
  max-width: 390px;
  min-width: 208px;
  height: fit-content;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(100, 100, 100, 0.15);
  padding: 20px 16px;

  @media (max-width: 1248px) {
    max-width: 100%;
  }
}

.filter-labels-wrapper {
  margin-top: 10px;
  width: 100%;
}

.filter-label {
  background-color: $brand-colour;
  padding: 3px 10px;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: auto;
}

.labels-divider-vertical {
  width: 1px;
  opacity: 0.5;
  background-color: #f9f9f9;
  height: 12px;
  margin-left: 6px;
  margin-right: 6px;
}

.label-gap {
  margin-right: 8px;
  margin-top: 5px;
}

.divider {
  height: 1px;
  background-color: $border-primary;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
}

.mantine-Accordion-control {
  padding-left: 0px !important;
  padding-right: 0px !important;

  &:hover {
    background-color: transparent !important;
  }
}

.mantine-Accordion-item {
  border: none !important;
}

.mantine-Accordion-chevron {
  color: $brand-colour !important;
  width: unset !important;
}

.mantine-Accordion-label {
  padding: 0px !important;
}

.mantine-Accordion-content {
  padding: 0px !important;
}

.product-filter-item-text {
  color: rgba(68, 79, 89, 0.6);
  cursor: pointer;
  margin-bottom: 10px;

  &.active {
    color: $brand-colour;
  }
}

.product-filter-main-item-text {
  color: #495057;
  cursor: pointer;

  &.active {
    color: $brand-colour;
  }
}

.chevron-option-wrapper {
  background-color: #f3f6f9;
  padding: 5px 7px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filters-number-indication {
  height: 17px;
  width: 17px;
  border-radius: 50%;
  background-color: $success;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
}

.mantine-Checkbox-root {
  margin-top: 0px !important;
}

.mantine-Checkbox-inner {
  height: 14px !important;
  width: 14px !important;
}

.mantine-Checkbox-input {
  height: 14px !important;
  width: 14px !important;
  border-color: $brand-colour !important;
  border-radius: 4px !important;
  cursor: pointer !important;
}

.mantine-Checkbox-label {
  height: 14px !important;
  margin-bottom: 0px !important;
  font-size: 13px;
  font-weight: 400;
  display: flex !important;
  align-items: center !important;
  padding-left: 7px !important;
  color: rgba(68, 79, 89, 0.6) !important;
  cursor: pointer !important;
}

.mantine-Checkbox-root[data-checked] {
  .mantine-Checkbox-body {
    .mantine-Checkbox-inner {
      .mantine-Checkbox-input {
        background-color: $brand-colour !important;
      }
    }
    .mantine-Checkbox-labelWrapper {
      .mantine-Checkbox-label {
        color: $brand-colour !important;
      }
    }
  }
}
