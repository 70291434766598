.mantine-Combobox-option {
  display: flex;

  .mantine-Checkbox-root {
    display: flex;
    justify-content: center;
    align-items: center;

    .mantine-Checkbox-body {
      height: 14px !important;
      width: 14px !important;
      border-radius: 4px;
    }

    .mantine-Checkbox-inner {
      height: 14px !important;
      width: 14px !important;
      border-radius: 4px;
    }

    .mantine-Checkbox-input {
      height: 14px !important;
      width: 14px !important;
      border-radius: 4px;
    }
  }

  .mantine-Group-root {
    gap: 6px !important;
  }
}

.check-all-wrapper {
  padding: 10px 10px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #dedede;

  .mantine-Checkbox-root {
    .mantine-Checkbox-body {
      height: 14px !important;
      width: 14px !important;
      border-radius: 4px;
    }

    .mantine-Checkbox-inner {
      height: 14px !important;
      width: 14px !important;
      border-radius: 4px;
    }

    .mantine-Checkbox-input {
      height: 14px !important;
      width: 14px !important;
      border-radius: 4px;
    }
  }
}

.check-all-option {
  width: 100%;
}

.mantine-Combobox-options .mantine-Combobox-option .mantine-Group-root {
  flex-wrap: unset;
  text-align: left;
}
