// .flatpickr-months {
//   display: flex;
//   background-color: #64A0E3 !important;
//   border-radius: 5px 5px 0px 0px;
// }

// .flatpickr-weekdays {
//   background-color: #64A0E3 !important;
//   text-align: center;
//   overflow: hidden;
//   width: 100%;
//   display: flex;
//   align-items: center;
//   height: 36px;
//   border-bottom: 1px solid var(--vz-border-color);
// }

// span.flatpickr-weekday {
//   cursor: default;
//   font-size: 90%;
//   background: #64A0E3 !important;
//   color: #fff;
//   line-height: 1;
//   margin: 0;
//   text-align: center;
//   display: block;
//   flex: 1 1;
//   font-weight: 500;
// }

// .flatpickr-current-month {
//   font-size: 100%;
//   line-height: inherit;
//   font-weight: 300;
//   color: inherit;
//   position: absolute;
//   width: 75%;
//   left: 12.5%;
//   padding: 8px 0 0 0 !important;
//   line-height: 1;
//   height: 34px;
//   display: inline-block;
//   text-align: center;
//   transform: translate3d(0px, 0px, 0px);
// }

// .flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
//   text-decoration: none;
//   cursor: pointer;
//   position: absolute;
//   top: 0;
//   height: 34px;
//   padding: 2px 10px;
//   z-index: 3;
//   color: rgba(255, 255, 255, 0.9);
//   fill: rgba(255, 255, 255, 0.9);
// }

// .flatpickr-day.today {
//   border: none !important;
//   background-color: $brand-colour;
//   box-shadow: none;
// }

// .flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
//   background: $brand-colour;
//   box-shadow: none;
//   color: #fff;
//   border: none !important;
// }

// .flatpickr-day.inRange {
//   background-color: #F2F2F2;
//   border: none;
//   width: 43.97 !important;
// }

// .dayContainer {
//   padding: 0;
//   outline: 0;
//   text-align: left;
//   width: 307.875px;
//   min-width: 307.875px;
//   max-width: 307.875px;
//   box-sizing: border-box;
//   display: inline-block;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center !important;
//   transform: translate3d(0px, 0px, 0px);
//   opacity: 1;
// }

// .flatpickr-days {
//   position: relative;
//   overflow: hidden;
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-align: start;
//   -ms-flex-align: start;
//   align-items: flex-start;
//   width: 307.875px;
// }

// .flatpickr-day {
//   background: none;
//   border: 1px solid transparent;
//   border-radius: 150px;
//   box-sizing: border-box;
//   color: var(--vz-body-color);
//   cursor: pointer;
//   font-weight: 400;
//   width: 14.2857143%;
//   flex-basis: 14.2857143%;
//   max-width: unset !important;
//   height: 43.97px !important;
//   line-height: 43.97px !important;
//   margin: 0;
//   display: inline-block;
//   position: relative;
//   justify-content: center;
//   text-align: center;
//   align-items: center;
// }

// .flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
//   background-color: #F2F2F2;
//   border: none;
//   width: 43.97 !important;
// }

//DATE PICKER NORMAL

.mantine-DatePicker-input {
  color: #444f59 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
}

.mantine-DatePicker-calendarHeader {
  background-color: $brand-colour !important;
  color: white !important;
  border-radius: 4px 4px 0px 0px !important;
  margin-bottom: 0px !important;
}

.mantine-DatePicker-calendarHeaderLevel:hover {
  background-color: $brand-colour !important;
}

.mantine-DatePicker-calendarHeaderControl {
  background-color: $brand-colour !important;
}

.mantine-DatePicker-monthThead {
  background-color: $brand-colour !important;
}

.mantine-DatePicker-weekday {
  color: white !important;
  width: auto !important;
  text-align: center !important;
}

.mantine-DatePicker-day[data-weekend] {
  color: black !important;
}

.mantine-DatePicker-day[data-selected] {
  border-radius: 50% !important;
  color: #444f59 !important;
  background-color: $brand-colour !important;
}

.mantine-DatePicker-day:hover {
  border-radius: 50% !important;
}

.mantine-DatePicker-day[data-in-range] {
  background-color: #f2f2f2 !important;
}

.mantine-DatePicker-day[data-first-in-range] {
  border-radius: 24px 0px 0px 24px !important;
  background-color: $brand-colour !important;
}

.mantine-DatePicker-day[data-last-in-range] {
  border-radius: 0px 24px 24px 0px !important;
  background-color: $brand-colour !important;
}

//DATE PICKER INPUT

.mantine-DatePickerInput-input {
  color: #444f59 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
}

.mantine-DatePickerInput-calendarHeader {
  background-color: $brand-colour !important;
  color: white !important;
  border-radius: 4px 4px 0px 0px !important;
  margin-bottom: 0px !important;
}

.mantine-DatePickerInput-calendarHeaderLevel:hover {
  background-color: $brand-colour !important;
}

.mantine-DatePickerInput-calendarHeaderControl {
  background-color: $brand-colour !important;
}

.mantine-DatePickerInput-monthThead {
  background-color: $brand-colour !important;
}

.mantine-DatePickerInput-weekday {
  color: white !important;
  width: auto !important;
  text-align: center !important;
}

.mantine-DatePickerInput-day[data-weekend] {
  color: black !important;
}

.mantine-DatePickerInput-day[data-selected] {
  border-radius: 50% !important;
  color: #444f59 !important;
  background-color: $brand-colour !important;
}

.mantine-DatePickerInput-day:hover {
  border-radius: 50% !important;
}

.mantine-DatePickerInput-day[data-in-range] {
  background-color: #f2f2f2 !important;
}

.mantine-DatePickerInput-day[data-first-in-range] {
  border-radius: 24px 0px 0px 24px !important;
  background-color: $brand-colour !important;
}

.mantine-DatePickerInput-day[data-last-in-range] {
  border-radius: 0px 24px 24px 0px !important;
  background-color: $brand-colour !important;
}

.dateinput-container {
  position: relative;
}

.dateinput-close-icon {
  position: absolute;
  transform: translateY(-50%);
  position: absolute;
  right: 0px;
  top: 50%;
}
