//
// _buttons.scss
//

button,
a {
  outline: none !important;
  font-size: 13px !important;
  line-height: 16px !important;
  font-weight: 500 !important;
}

.btn {
  box-shadow: $element-shadow;
}

:is(.btn.show, .btn:first-child:active, :not(.btn-check) + .btn:active) {
  border-color: transparent;
}

.btn-icon {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: calc(
    #{$btn-padding-y * 2} + #{$btn-line-height}em + #{$border-width * 2}
  );
  width: calc(
    #{$btn-padding-y * 2} + #{$btn-line-height}em + #{$border-width * 2}
  );
  padding: 0;

  :is(i, svg, img) {
    vertical-align: middle;
  }

  &.btn-sm {
    height: calc(
      #{$btn-padding-y-sm * 2} + #{$btn-line-height}em + #{$border-width * 2}
    );
    width: calc(
      #{$btn-padding-y-sm * 2} + #{$btn-line-height}em + #{$border-width * 2}
    );
  }

  &.btn-lg {
    height: calc(
      #{$btn-padding-y-lg * 2} + #{$btn-line-height}em + #{$border-width * 2}
    );
    width: calc(
      #{$btn-padding-y-lg * 2} + #{$btn-line-height}em + #{$border-width * 2}
    );
  }
}

//
// Soft Buttons
//

.hover-soft-button-text {
  &:hover {
    color: white !important;
  }
}

@each $state in map-keys($theme-colors) {
  .btn-#{$state} {
    --#{$prefix}btn-color: white;
    --#{$prefix}btn-hover-color: white;
    --#{$prefix}btn-bg: var(--#{$prefix}#{$state});
    --#{$prefix}btn-border-color: var(--#{$prefix}#{$state});
    --#{$prefix}btn-hover-bg: var(--#{$prefix}#{$state}-text-emphasis);
    --#{$prefix}btn-hover-border-color: var(
      --#{$prefix}#{$state}-text-emphasis
    );
    --#{$prefix}btn-focus-shadow-rgb: var(--#{$prefix}#{$state}-rgb);
    --#{$prefix}btn-active-bg: var(--#{$prefix}#{$state}-text-emphasis);
    --#{$prefix}btn-active-border-color: var(
      --#{$prefix}#{$state}-text-emphasis
    );
  }

  .btn-outline-#{$state} {
    --#{$prefix}btn-color: var(--#{$prefix}#{$state});
    --#{$prefix}btn-bg: transparent;
    --#{$prefix}btn-border-color: var(--#{$prefix}#{$state});
    --#{$prefix}btn-hover-bg: var(--#{$prefix}#{$state});
    --#{$prefix}btn-hover-border-color: var(--#{$prefix}#{$state});
    --#{$prefix}btn-focus-shadow-rgb: var(--#{$prefix}#{$state}-rgb);
    --#{$prefix}btn-active-bg: var(--#{$prefix}#{$state});
    --#{$prefix}btn-active-border-color: var(--#{$prefix}#{$state});
  }

  // soft Buttons

  .btn-soft-#{$state} {
    --#{$prefix}btn-color: var(--#{$prefix}#{$state});
    --#{$prefix}btn-bg: var(--#{$prefix}#{$state}-bg-subtle);
    --#{$prefix}btn-border-color: transparent;
    --#{$prefix}btn-hover-bg: var(--#{$prefix}#{$state});
    --#{$prefix}btn-hover-border-color: transparent;
    --#{$prefix}btn-focus-shadow-rgb: var(--#{$prefix}#{$state}-rgb);
    --#{$prefix}btn-active-bg: var(--#{$prefix}#{$state});
    --#{$prefix}btn-active-border-color: transparent;
    --#{$prefix}btn-hover-color: white !important;
  }

  // Ghost Buttons
  .btn-ghost-#{$state} {
    --#{$prefix}btn-color: var(--#{$prefix}#{$state});
    --#{$prefix}btn-bg: transparent;
    --#{$prefix}btn-border-color: transparent;
    --#{$prefix}btn-hover-color: var(--#{$prefix}#{$state});
    --#{$prefix}btn-hover-bg: var(--#{$prefix}#{$state}-bg-subtle);
    --#{$prefix}btn-hover-border-color: transparent;
    --#{$prefix}btn-focus-shadow-rgb: var(--#{$prefix}#{$state}-rgb);
    --#{$prefix}btn-active-color: var(--#{$prefix}#{$state});
    --#{$prefix}btn-active-bg: var(--#{$prefix}#{$state}-bg-subtle);
    --#{$prefix}btn-active-border-color: transparent;
  }
}

//button with icon
.btn-label {
  position: relative;
  padding-left: 44px;

  .label-icon {
    position: absolute;
    width: 35.5px;
    left: calc(#{$btn-border-width} * -1);
    top: calc(#{$btn-border-width} * -1);
    bottom: calc(#{$btn-border-width} * -1);
    background-color: rgba($white, 0.1);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.btn-light {
    .label-icon {
      background-color: rgba($dark, 0.05);
    }
  }

  &.right {
    padding-left: $btn-padding-x;
    padding-right: 44px;

    .label-icon {
      right: calc(#{$btn-border-width} * -1);
      left: auto;
    }
  }
}

// Animation Button

.btn-animation {
  overflow: hidden;
  transition:
    border-color 0.3s,
    background-color 0.3s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);

  &::after {
    content: attr(data-text);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transform: translate3d(0, 25%, 0);
    padding: $btn-padding-y $btn-padding-x;
    transition:
      transform 0.3s,
      opacity 0.3s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  }

  > span {
    display: block;
    transition:
      transform 0.3s,
      opacity 0.3s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  }

  &:hover {
    &::after {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    > span {
      opacity: 0;
      transform: translate3d(0, -25%, 0);
    }
  }
}

@mixin button-variant($bg) {
  &.btn-animation {
    background-color: $bg;
    border-color: $bg !important;

    &:is(:hover, :focus, :active, :focus-visible) {
      color: $bg;
      background-color: rgba($bg, 0.1);
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value);
  }
}

//btn group
.btn-group-vertical {
  label {
    margin-bottom: 0;
  }
}

// btn group radio
.btn-group.radio {
  .btn {
    border: none;
  }

  .btn-check:active + .btn-light,
  .btn-check:checked + .btn-light,
  .btn-light.active,
  .btn-light.dropdown-toggle.show,
  .btn-light:active {
    background-color: rgba($info, 0.2);
    color: $info;
  }
}

.btn-load {
  .spinner-border,
  .spinner-grow {
    height: 19px;
    width: 19px;
  }
}

//Border Bottom Buttons Style
@each $color, $value in $theme-colors {
  :is(.btn-#{$color}, .btn-outline-#{$color}, .btn-soft-#{$color}) {
    &.btn-border {
      border-bottom: 2px solid darken($value, 8%);
    }
  }

  :is(.btn-soft-#{$color}, .btn-outline-#{$color}) {
    --#{$prefix}btn-active-color: #{color-contrast($value)};
    --#{$prefix}btn-hover-color: #{color-contrast($value)};
  }
}

//custom toggle
.custom-toggle {
  .icon-off {
    display: none;
  }

  &.active {
    .icon-on {
      display: none;
    }

    .icon-off {
      display: block;
    }
  }
}
